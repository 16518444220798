import React, { PureComponent }from "react"
import Layout2 from "../components/layout2"
import Fade from 'react-reveal/Fade'
import Counter from './../elements/counter.js'
import LineChart from './../elements/linechart.js'
import Signature from './../elements/signature.js'
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Img from 'gatsby-image'
import Applause from './../elements/applause.js'
import Button from './../elements/button.js'
import EmailSignup from './../components/emailsignup.js'
import Footer from "./../components/footer.js"

export default class Stats extends PureComponent {

  constructor(){
    super();
    this.state = {
        data: [],
        time: [],
        data_loaded:false,
        time_loaded:false,
    }
}

  componentDidMount() {
      fetch("https://www.googleapis.com/download/storage/v1/b/brett-bejcek-site-data/o/todays_numbers.json?alt=media")
          .then( (response) => {
              return response.json() })
                  .then( (json) => {
                      this.setState({data: json, data_loaded:true})
                  })
      fetch("https://www.googleapis.com/download/storage/v1/b/brett-bejcek-site-data/o/last_updated.json?alt=media")
          .then( (response) => {
              return response.json() })
                  .then( (json) => {
                      this.setState({time: json, time_loaded:true})
                  })
  }
  render() {
    if (this.state.data_loaded && this.state.time_loaded) {
    return (

<Layout2>
<StaticQuery
  query={graphql`
    query {
      fileName: file(relativePath: { eq: "img/stats/stats_preview.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `}
  render={data => (
    <Helmet title="Brett Bejcek: My Stats">
  <meta name="description" content="I show these stats to the world to bridge my inner and outer self. This page is me. It is me on the days where I missed a run. It is me on the days where I got into a fight with a friend or where I lacked motivation. It is also me on the days that I totally won. It is me on the days where I slayed in the meeting or when I made my friend's birthday extra special. This me: 100% authentically living." />
  <meta name="image" content={data.fileName.childImageSharp.fluid.src} />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Brett Bejcek: My Stats" />
  <meta property="og:description" content="I show these stats to the world to bridge my inner and outer self. This page is me. It is me on the days where I missed a run. It is me on the days where I got into a fight with a friend or where I lacked motivation. It is also me on the days that I totally won. It is me on the days where I slayed in the meeting or when I made my friend's birthday extra special. This me: 100% authentically living." />
  <meta property="og:image" content={data.fileName.childImageSharp.fluid.src} />
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:title" content="Brett Bejcek: My Stats" />
  <meta name="twitter:description" content="I show these stats to the world to bridge my inner and outer self. This page is me. It is me on the days where I missed a run. It is me on the days where I got into a fight with a friend or where I lacked motivation. It is also me on the days that I totally won. It is me on the days where I slayed in the meeting or when I made my friend's birthday extra special. This me: 100% authentically living." />
  <meta name="twitter:image" content={data.fileName.childImageSharp.fluid.src} />
    </Helmet>
  )}
/>
<div css = {{marginLeft: `auto`, marginRight:`auto`, textAlign: `center`, maxWidth:`700px`, padding:`5%`}}>
<div css = {{height:`100vh`}}>
<Fade>
<h1 css = {{position:`absolute`, bottom:`8rem`, left:`12rem`,right:`12rem`, '@media (max-width: 900px)': {left:`1rem`, right:`1rem`}}}> Brett's take on living a data-driven life </h1>
</Fade>
</div>

<Fade delay = {750}>
<p css = {{fontStyle:`italic`, color:`gray`, position:`absolute`, bottom:`4.5rem`, left:`0.1rem`,right:`0.1rem`}}> Data was updated {Math.floor((Date.now() - (parseFloat(this.state.time.last_updated)*1000))/60000)} minutes ago.</p>
</Fade>

<Fade delay = {2200}>

<p css = {{fontSize:`small`,color:`gray`, position:`absolute`, bottom:`1.5rem`, left:`0.1rem`,right:`0.1rem`}}> [KEEP SCROLLING] </p>
</Fade>

<Fade>
<div css = {{marginTop:`10rem`}}>
<p css = {{fontSize:`larger`}}> I keep a pretty close eye on what my life is telling me via the data it produces. Some stats are just <i>fun facts</i>. For example... </p>
</div>
</Fade>
<div css = {{marginBottom:`18rem`}} />
<Counter  number = {this.state.data.days_since_international} fade_below = {700} decimals = {0} duration = "3" fontSize = "9rem" color= "#7d4aa3" before_text = "IT HAS BEEN" after_text = "DAYS SINCE I HAVE BEEN OUT OF THE CONTINGENT UNITED STATES"/>
<div css = {{marginBottom:`18rem`}} />
<Counter number = {this.state.data.hours_since_coffeeshop} fade_below = {700} decimals = {1} duration = "3" fontSize = "9rem" color= "#7d4aa3" before_text = "IT HAS BEEN" after_text = "HOURS SINCE I HAVE BEEN AT A COFFEESHOP"/>
<div css = {{marginBottom:`18rem`}} />
<Counter number = {this.state.data.days_since_code} fade_below = {700} decimals = {0} duration = "3" fontSize = "9rem" color= "#7d4aa3" before_text = "IT HAS BEEN" after_text = "DAYS SINCE I HAVE PUSHED OPEN-SOURCE CODE"/>

<Fade>
<div css = {{marginBottom:`20rem`, marginTop:`20rem`}}>
<p css = {{fontSize:`larger`}}> Others get a little more <b>personal...</b></p>
</div>
</Fade>

<Fade>
<div css = {{marginBottom:`20rem`, marginTop:`20rem`}}>
<p css = {{fontSize:`larger`}}>I map my values of <i>drive, zest,</i> and <i>curiosity</i> back to: <b><i> physical, social,</i></b> and <b><i> mental </i></b> health (respectively). </p>
</div>
</Fade>

<Fade>
<div css = {{marginTop:`20rem`, marginBottom:`10rem`}}>
<p css = {{fontSize:`larger`}}>Here's a view of what this looks like. This is my life in numbers:</p>
</div>
</Fade>

<Fade>
<p className = "trailing" css = {{width:`50%`, marginLeft:`auto`, marginRight:`auto`,fontWeight:`bold`, '@media (max-width: 550px)': {width:`75%`}}}>PHYSICAL HEALTH</p>
<LineChart number = {this.state.data.steps_today} textAbove = "IN THE PAST 24 HOURS, I HAD" textBelow="STEPS" fontSize = "9rem" duration = "5" color = "#7d4aa3" name = "steps_chart" chart = "https://www.googleapis.com/download/storage/v1/b/brett-bejcek-site-data/o/steps_trailing_chart.svg?alt=media"/>
<div css = {{marginBottom:`12rem`}}/>
</Fade>

<Fade>
<p className = "trailing" css = {{width:`50%`, marginLeft:`auto`, marginRight:`auto`,fontWeight:`bold`, '@media (max-width: 550px)': {width:`75%`}}}>SOCIAL HEALTH</p>
<LineChart number = {this.state.data.places_today} textAbove = "IN THE PAST 24 HOURS, I VISITED" textBelow="UNIQUE PLACES" fontSize = "9rem" duration = "5" color = "#7d4aa3" name = "social_chart" chart = "https://www.googleapis.com/download/storage/v1/b/brett-bejcek-site-data/o/social_trailing_chart.svg?alt=media"/>
<div css = {{marginBottom:`12rem`}}/>
</Fade>

<Fade>
<p className = "trailing" css = {{width:`50%`, marginLeft:`auto`, marginRight:`auto`,fontWeight:`bold`, '@media (max-width: 550px)': {width:`75%`}}}>MENTAL HEALTH</p>
<LineChart number = {this.state.data.mental_today} textAbove = "IN THE PAST 24 HOURS, I HAD A SCORE OF" textBelow="OUT OF 100 FOR MENTAL HEALTH" fontSize = "9rem" duration = "5" color = "#7d4aa3" name = "mental_chart" chart = "https://www.googleapis.com/download/storage/v1/b/brett-bejcek-site-data/o/mental_trailing_chart.svg?alt=media"/>
<div css = {{marginBottom:`12rem`}}/>
</Fade>

<Fade>
<div css = {{marginBottom:`5rem`, marginTop:`20rem`}}>
<p css = {{fontSize:`larger`}}>I show these stats to the world to bridge my inner and outer self. <b>What you just saw was me</b>. It was me on the days where I missed a run. It was me on the days where I got into a fight with a friend. It was me on the days where I lacked motivation. It was also me on the days that I totally won. It was me on the days where I slayed in the meeting or when I made my friend's birthday extra special. The above is me: <b>100% authentically living</b>. Thanks for taking the time to get to know me. </p>
</div>
</Fade>

<Fade>
<div css = {{marginBottom:`5rem`, marginTop:`5rem`, width:`30%`, marginLeft:`auto`, marginRight:`auto`,'@media (max-width: 767px)': {width:`50%`}}}>
<Signature/>
</div>
</Fade>
<Fade>
<Applause path = "/stats"/>
<br/>
<br/>
<Button link = "/" cta = "back to home"/>
<br/>
<br/>
</Fade>

</div>
<div css={{maxWidth:`700px`, marginLeft:`auto`, marginRight:`auto`}}>
<EmailSignup/>
<Footer/>
</div>
</Layout2>);}
else{return(<div css = {{height:`100vh`}}/>);}
}
}
